<template>
    <div id="websites-list" class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("Websites") }}</div>
        </div>
        <div class="card-body">
            <data-table
                id="websites-list"
                :entity-name="entityName"
                :columns="visible_columns"
                :rows="filtered_rows"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="!!userId"
                :searchable="false"
                :show-head="false"
                @sort="sort_by"
                @page="goto_page"
                @add="record_create"
                @click="record_edit($event)"
            >
                <template
                    v-slot:tbody="{ row }"
                >
                    <div class="d-flex">
                        <div v-if="!!row.image_url" class="flex-static mr-2">
                            <responsive-image
                                :src="row.image_url"
                                :alt="row.name"
                            />
                        </div>
                        <div class="flex-variable ml-2">
                            {{ row.name }}
                            <base-link
                                v-if="!!row.url"
                                :href="row.url"
                                target="_blank"
                                class="ml-2"
                                @click.stop
                            >
                                <open-icon glyph="external-link-alt" />
                            </base-link>
                            <div class="text-gray text-small">
                                <span>
                                    {{ translate("1 listing:::{n} listings", { n: nibnut_filter("nibnut.number", [row.listing_count, "0,0"]) }, row.listing_count) }}
                                </span>
                                <default-button
                                    flavor="link"
                                    size="sm"
                                    :color="is_copied(row.id) ? 'success' : 'regular'"
                                    class="float-right"
                                    @click.prevent.stop="copy(row)"
                                >
                                    {{ translate("API Key: {key}", { key: row.jwt_secret }) }}
                                </default-button>
                            </div>
                        </div>
                    </div>
                </template>
            </data-table>

            <modal-dialog
                id="website-editor"
                :show.sync="editing"
            >
                <template v-slot:title>
                    <span v-if="!!edited_record && !!edited_record.name" class="h5">{{ edited_record.name }}</span>
                    <span v-else class="h5">{{ $root.translate("New Website") }}</span>
                </template>
                <base-form
                    v-if="edited_record"
                    :has-required-fields="true"
                    @submit.prevent="save_editor"
                >
                    <section>
                        <form-input
                            id="name"
                            name="name"
                            v-model="edited_record.name"
                            :required="true"
                            :saving="saving('name')"
                            :error="has_error('name')"
                            @input="save"
                        >
                            <template v-slot:label>
                                {{ translate("Name") }}
                            </template>
                        </form-input>
                        <form-url-input
                            id="url"
                            name="url"
                            v-model="edited_record.url"
                            :required="true"
                            :saving="saving('url')"
                            :error="has_error('url')"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("URL") }}</template>
                        </form-url-input>
                    </section>
                    <section class="mt-4">
                        <h6>{{ translate("Google Analytics") }}</h6>
                        <google-analytics
                            :record="edited_record"
                            @input="save_analytics"
                        />
                    </section>
                    <section class="mt-4">
                        <h6>{{ translate("Meta Data") }}</h6>
                        <metadata-input
                            v-if="!!edited_record && !!edited_record.metadata"
                            id="website-metadata"
                            :value="edited_record.metadata"
                            :hidden-fields="['description']"
                            @input="save"
                        >
                            <form-token-input
                                id="website-metadata-agents"
                                name="agents"
                                :value="edited_record.metadata.agents"
                                :required="false"
                                :separators="[',','Enter']"
                                @input="add_agent"
                                @delete="remove_agent"
                            >
                                <template v-slot:label>{{ translate("Team Members' Names") }}</template>
                            </form-token-input>
                        </metadata-input>
                    </section>
                </base-form>

                <template v-slot:footer>
                    <div
                        v-if="!!edited_record && !edited_record.id"
                        class="text-center"
                    >
                        <default-button
                            :disabled="creating_record"
                            class="mx-2"
                            @click.prevent="close_editor"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                        <default-button
                            color="primary"
                            :disabled="creating_record"
                            :waiting="creating_record"
                            class="mx-2"
                            @click.prevent="save_editor"
                        >
                            {{ $root.translate("Create") }}
                        </default-button>
                    </div>
                </template>
            </modal-dialog>
        </div>
    </div>
</template>

<script>
import copy from "copy-text-to-clipboard"

import { is_remote_data_table_source, is_record_editor } from "@/nibnut/mixins"

import { ModalDialog, BaseForm, FormInput, FormTokenInput, DefaultButton, BaseLink, OpenIcon, ResponsiveImage } from "@/nibnut/components"
import FormUrlInput from "@/custom/components/FormUrlInput"
import GoogleAnalytics from "@/custom/components/GoogleAnalytics"
import MetadataInput from "@/custom/components/MetadataInput"

export default {
    name: "WebsitesList",
    mixins: [is_remote_data_table_source, is_record_editor],
    components: {
        ModalDialog,
        BaseForm,
        FormInput,
        FormTokenInput,
        DefaultButton,
        BaseLink,
        OpenIcon,
        ResponsiveImage,
        FormUrlInput,
        GoogleAnalytics,
        MetadataInput
    },
    watch: {
        userId: "reset"
    },
    methods: {
        pre_load () {
            this.set_state_value("filter", this.userId)
        },
        record_edit (record) {
            this.record_id = record.id
            this.editing = true
        },
        record_create () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                {
                    entity: this.entity
                }
            ).then(record => {
                record.user_id = this.userId
                this.shell_record = record
                this.record_edit(record)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        grab_record_id () {
        },
        save_analytics (settings) {
            if(this.record_id) this.save_data_for_record_id(this.entity, this.record_id, settings)
        },
        close_editor () {
            this.editing = false
            this.record_id = 0
        },
        save_editor () {
            const edited_record = this.edited_record
            const is_new = !edited_record.id
            if(is_new) this.creating_record = true
            return this.save_data_for_record_id(this.entity, edited_record.id || 0, edited_record).then(record => {
                this.creating_record = false
                if(is_new && record) this.close_editor()
            })
        },
        is_copied (id) {
            return !!this.copied[id]
        },
        copy (row) {
            if(this.is_copied(row.id)) {
                clearTimeout(this.copied[row.id])
                this.$set(this.copied, row.id, null)
            }
            copy(row.jwt_secret)
            this.$set(this.copied, row.id, setTimeout(() => {
                this.$set(this.copied, row.id, null)
            }, 300))
        },
        save_metadata (value, field) {
            const metadata = { ...this.edited_record.metadata }
            metadata[field] = value
            this.save(metadata, "metadata")
        },
        add_agent (agent) {
            const agents = [...this.edited_record.metadata.agents]
            const index = agents.indexOf(agent)
            if(index < 0) agents.push(agent)
            this.save_metadata(agents, "agents")
        },
        remove_agent (agent) {
            const agents = [...this.edited_record.metadata.agents]
            const index = agents.indexOf(agent)
            if(index >= 0) agents.splice(index, 1)
            this.save_metadata(agents, "agents")
        }
    },
    computed: {
        state_identifier () {
            return "websites-list"
        },
        fields () {
            return ["fieldset::default"]
        },
        can_reload () {
            return false
        }
    },
    props: {
        userId: {
            type: Number,
            validator: prop => (prop >= 0),
            default: 0
        }
    },
    data () {
        return {
            entity: "website",
            entityName: "website:::websites",
            relation_ids: [],
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: "user_id",
                filter: 0,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            apply_local_filter: false,
            editing: false,
            copied: {}
        }
    }
}
</script>

<style lang="scss">
#websites-list {
    td img {
        height: 2.1rem;
    }
}
</style>
