<template>
    <div class="card top-listings">
        <div class="card-header">
            <div class="card-title h5">{{ title }}</div>
            <div class="card-subtitle text-gray">{{ subTitle }}</div>
        </div>
        <div class="card-body">
            <div
                v-if="!records || !records.length"
                class="empty"
            >
                <div class="empty-icon">
                    <open-icon
                        glyph="stopwatch"
                        size="3x"
                    />
                </div>
                <p class="empty-title h5">{{ translate("Not enough data yet...") }}</p>
                <slot name="subtitle">
                    <p class="empty-subtitle">{{ translate("As soon as stats start coming in, we will show you your top listings here.") }}</p>
                </slot>
            </div>
            <table
                v-else
                class="table"
            >
                <tr
                    v-for="record in records"
                    :key="record.id"
                >
                    <td>
                        <router-link
                            :to="{ name: 'listing.edit', params: {id: record.id } }"
                            v-slot="{ href, navigate }"
                            custom
                        >
                            <base-link
                                :href="href"
                                @click.stop="navigate"
                            >
                                {{ record.computed_display_title }}
                            </base-link>
                        </router-link>
                        <div v-if="record.current_chance_of_selling" class="text-small text-gray">
                            {{ translate("Chance of selling") }}: {{ record.current_chance_of_selling | nibnut.number("0,0") }}%
                        </div>
                    </td>
                    <td class="text-right">
                        {{ record[field] | nibnut.number("0,0") }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "TopListings",
    components: {
        BaseLink,
        OpenIcon
    },
    props: {
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String
        },
        records: {
            type: Array,
            default () {
                return []
            }
        },
        field: {
            type: String,
            default: "count"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.top-listings {
    height: 443px;

    .card-body {
        padding-left: 0;
        padding-right: 0;
        overflow: auto;

        table.table {
            td {
                padding-left: $layout-spacing-lg;
                padding-right: $layout-spacing-lg;
            }
        }

        .empty {
            min-height: 341px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1.175rem;
            margin-left: $layout-spacing-lg;
            margin-right: $layout-spacing-lg;

            .empty-icon {
                margin: 0;
            }
        }
    }
}
</style>
