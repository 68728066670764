<template>
    <base-form
        v-if="!!record"
        :has-required-fields="true"
        :class="{ extended }"
        @submit.prevent="$emit('submit')"
    >
        <form-select
            v-if="!record.id"
            id="listing_id"
            name="listing_id"
            :value="record ? record.listing_id : 0"
            data-source="listing"
            :empty-value="0"
            :empty-label="$root.translate('MLS #, Property # or address')"
            label-field="computed_display_title"
            :filter-fields="['mls_id', 'id']"
            :show-all="true"
            :ad-hoc="false"
            :required="!listing || !listing.id"
            @input="pick"
        >
            <template v-slot:label>{{ translate("Listing") }}</template>
            <template v-slot:option="{ option }">
                <span v-if="!option.id">{{ option.computed_display_title }}</span>
                <listing-compact-list-display
                    v-else
                    :record="option"
                />
            </template>
        </form-select>
        <listing-compact-list-display
            v-if="!!listing && !!listing.id"
            :record="listing"
        />

        <div
            v-if="can_schedule && !record.has_emails"
            class="mt-4"
        >
                <form-input
                    id="from"
                    name="from"
                    v-model="record.from"
                    :required="false"
                    @input="save"
                >
                    <template v-slot:label>
                        {{ translate("Realtor") }}
                    </template>
                </form-input>
                <form-input
                    id="reply_url"
                    name="reply_url"
                    type="email"
                    :value="reply_url"
                    :required="false"
                    @input="save_reply_url"
                >
                    <template v-slot:label>
                        {{ translate("Email") }}
                    </template>
                </form-input>
        </div>

        <vue-cal
            v-if="withCalendar"
            active-view="week"
            :disable-views="['years', 'year', 'month']"
            :min-date="$dayjs().toDate()"
            :editable-events="true"
            :time-from="9 * 60"
            :time-to="20 * 60"
            :time-cell-height="80"
            :snap-to-time="15"
            :events="events"
        >
            <template v-slot:weekday-heading="{ heading }">
                {{ heading.date | nibnut.date("ddd D") }}
            </template>
        </vue-cal>
        <div
            v-else-if="can_schedule"
            :class="{ 'justify-content-center': !can_schedule || !!record.has_emails }"
            class="columns mt-4"
        >
            <div
                :class="{ 'flex-shrink': !can_schedule || !!record.has_emails }"
                class="column"
            >
                <form-date-input
                    id="day"
                    name="day"
                    :value="record.starts_at"
                    position="right"
                    :min="today"
                    calendar-size="sm"
                    :editable="can_schedule && !record.has_emails"
                    :required="true"
                    @input="save_date"
                    @focus="extended = true"
                    @blur="extended = false"
                />
            </div>
            <div
                :class="{ 'flex-shrink': !can_schedule || !!record.has_emails }"
                class="column"
            >
                <form-input
                    id="starts_at"
                    name="starts_at"
                    :value="starts_at"
                    type="time"
                    :editable="can_schedule && !record.has_emails"
                    :required="true"
                    @input="save_date"
                />
            </div>
            <div class="column flex-static px-1">
                <label class="form-label">{{ translate("to") }}</label>
            </div>
            <div
                :class="{ 'flex-shrink': !can_schedule || !!record.has_emails }"
                class="column"
            >
                <form-input
                    id="ends_at"
                    name="ends_at"
                    :value="ends_at"
                    type="time"
                    :editable="can_schedule && !record.has_emails"
                    :required="true"
                    @input="save_date"
                />
            </div>
        </div>

        <base-link
            v-if="!!record.has_emails && can_schedule"
            :href="record.has_emails"
            :disabled="!!record.confirmed_at"
            class="btn btn-primary btn-block mt-4"
        >
            <span v-if="!!record.confirmed_at">{{ translate("Confirmed") }}</span>
            <span v-else>{{ translate("Confirm") }}<open-icon glyph="external-link-alt" class="ml-2" /></span>
        </base-link>
        <form-toggle-input
            v-else-if="!record.has_emails && can_schedule"
            id="confirmed_at"
            name="confirmed_at"
            type="checkbox"
            :value="!!record.confirmed_at"
            :editable="true"
            :required="false"
            @input="toggle_confirmed_at"
        >
            {{ translate("Confirmed") }}
        </form-toggle-input>
    </base-form>
</template>

<script>
import { mapGetters } from "vuex"
import handles_saving from "@/nibnut/mixins/HandlesSaving"

import VueCal from "vue-cal"
import "vue-cal/dist/vuecal.css"

import BaseForm from "@/nibnut/components/BaseForm"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import FormDateInput from "@/nibnut/components/Inputs/FormDateInput"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"
import ListingCompactListDisplay from "@/custom/components/ListingCompactListDisplay"

export default {
    name: "ShowingEditor",
    mixins: [handles_saving],
    components: {
        BaseForm,
        FormSelect,
        FormDateInput,
        FormInput,
        FormToggleInput,
        BaseLink,
        OpenIcon,
        ListingCompactListDisplay,
        VueCal
    },
    mounted () {
        this.reset_defaults()
    },
    watch: {
        "record.id": "reset_defaults"
    },
    methods: {
        reset_defaults () {
            if(!!this.record && !!this.record.listing_id) {
                this.listing = this.entity_record("listing", this.record.listing_id) || { id: 0 }
            } else {
                this.listing = { id: 0 }
            }
        },
        pick (value, field, option) {
            this.listing = option
            this.record.listing_id = this.listing.id
        },
        save_date (value, field) {
            const merge_date = (existing_date, merging_time = false) => {
                if(existing_date) {
                    if(merging_time) {
                        value = value.date(existing_date.date())
                        value = value.month(existing_date.month())
                        value = value.year(existing_date.year())
                    } else {
                        value = value.millisecond(0)
                        value = value.second(0)
                        value = value.minute(existing_date.minute())
                        value = value.hour(existing_date.hour())
                    }
                }
                return value.format("YYYY-MM-DD HH:mm:ss")
            }
            if(field === "day") {
                value = this.$dayjs(value)
                this.record.starts_at = merge_date(this.$dayjs(this.record.starts_at))
                this.record.ends_at = merge_date(this.$dayjs(this.record.ends_at))
            } else {
                value = this.$dayjs(value, "HH:mm")
                if(field === "starts_at") {
                    this.record.starts_at = merge_date(this.$dayjs(this.record.starts_at), true)
                } else if(field === "ends_at") {
                    this.record.ends_at = merge_date(this.$dayjs(this.record.ends_at), true)
                }
            }
            if(this.record.id) {
                this.save_data_for_record_id(this.entity, this.record.id, {
                    starts_at: this.record.starts_at,
                    ends_at: this.record.ends_at
                })
            }
            this.$emit("saved")
            if(field === "day") {
                const node = document.querySelector("#starts_at")
                if(node) node.focus()
            }
        },
        save_reply_url (value) {
            this.save(value ? `mailto:${value}` : null, "reply_url")
        },
        save (value, field) {
            this.record_id = this.record.id
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
                if(this.record.id) this.save_field(this.record[field], field)
                this.$emit("saved")
            }
        },
        toggle_confirmed_at () {
            this.save(this.record.confirmed_at ? null : this.$dayjs().format("YYYY-MM-DD HH:mm:ss"), "confirmed_at")
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        today () {
            return new Date()
        },
        is_past () {
            return !!this.record && !!this.record.starts_at && this.$dayjs(this.record.starts_at).isSameOrBefore(new Date(), "minute")
        },
        can_schedule () {
            return !this.is_past || !this.record.id
        },
        starts_at () {
            if(!!this.record && !!this.record.starts_at) return this.$dayjs(this.record.starts_at).format("HH:mm")
            return ""
        },
        ends_at () {
            if(!!this.record && !!this.record.ends_at) return this.$dayjs(this.record.ends_at).format("HH:mm")
            return ""
        },
        events () {
            // ***** add feedback list and editor...
            return [
                { id: 1, title: "1 Main Street...", mls_id: "1000", start: "2022-04-25 10:00", end: "2022-04-25 10:30", content: "<i class=\"las la-check-circle\"></i>", class: "confirmed" },
                { id: 2, title: "2 Main Street...", mls_id: "1001", start: "2022-04-25 10:30", end: "2022-04-25 11:00" },
                { id: 3, title: "3 Main Street...", mls_id: "1002", start: "2022-04-26 15:00", end: "2022-04-26 15:30" },
                { id: 4, title: "4 Main Street...", mls_id: "1003", start: "2022-04-27 11:00", end: "2022-04-27 11:30", content: "<i class=\"las la-check-circle\"></i>", class: "confirmed" },
                { id: 5, title: "5 Main Street...", mls_id: "1004", start: "2022-04-29 10:00", end: "2022-04-29 10:30" },
                { id: 6, title: "6 Main Street...", mls_id: "1005", start: "2022-04-29 10:30", end: "2022-04-29 11:00", content: "<i class=\"las la-check-circle\"></i>", class: "confirmed" },
                { id: 7, title: "7 Main Street...", mls_id: "1006", start: "2022-04-29 11:00", end: "2022-04-29 11:30", content: "<i class=\"las la-check-circle\"></i>", class: "confirmed" },
                { id: 8, title: "8 Main Street...", mls_id: "1007", start: "2022-04-29 14:00", end: "2022-04-29 14:30", content: "<i class=\"las la-check-circle\"></i>", class: "confirmed" },
                { id: 9, title: "9 Main Street...", mls_id: "1008", start: "2022-04-29 14:30", end: "2022-04-29 15:00" },
                { id: 10, title: "10 Main Street...", mls_id: "1009", start: "2022-04-30 10:00", end: "2022-04-30 10:30" }
            ]
        },
        reply_url () {
            if(!this.record) return null
            return this.record.reply_url ? this.record.reply_url.replace(/^mailto:/i, "") : null
        }
    },
    props: {
        record: { // shell or existing listing_showing record
            type: Object
        },
        withCalendar: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            entity: "listing_showing",
            listing_id: 0,
            listing: { id: 0 },
            extended: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.vuecal {
    font-size: 0.8em;
    height: 40vh;
    .vuecal__event {
        &.confirmed {
            background-color: $primary-color;
            color: $light-color;
        }
        &.new {
            background-color: $tertiary-color;
            color: $light-color;
        }
    }
    @media (max-width: $size-md) {
        height: 40vh;
    }
}
</style>
