<template>
    <div>
        <div class="timeline">
            <timeline-item
                v-if="editable"
                :event="null"
                :listing-id="listing.id"
                :editable="editable"
            />
            <timeline-item
                v-for="event in filtered_events(1)"
                :key="`${event.type}-${event.id}`"
                :event="event"
                :listing-id="listing.id"
                :editable="editable"
                @edit-feedback="edit_feedback"
                @delete="delete_event"
            />
            <div
                v-if="!editable || filtered_events(1).length"
                class="timeline-item"
            >
                <div class="timeline-right">
                    {{ translate("Now") }}
                </div>
                <div class="timeline-left">
                    <span class="timeline-icon icon-lg bg-gray"></span>
                </div>
                <div class="timeline-content">
                </div>
            </div>
            <timeline-item
                v-for="event in filtered_events(-1)"
                :key="`${event.type}-${event.id}`"
                :event="event"
                :listing-id="listing.id"
                :editable="editable"
                @edit-feedback="edit_feedback"
                @delete="delete_event"
            />
        </div>
        <feedback-editor-dialog
            v-if="editable && editing"
            :show.sync="editing"
            :record="edited_feedback"
            :listing="listing"
        />
        <modal-dialog
            v-else-if="editing"
            id="timeline-feedback-viewer"
            :show.sync="editing"
        >
            <template v-slot:title>
                <h5 v-if="!!edited_feedback">
                    {{ translate("Feedback") }}
                    <div v-if="!!listing" class="text-small text-gray">
                        {{ listing.computed_display_title }}<span v-if="edited_feedback.showed_at">&nbsp;&bull;&nbsp;{{ edited_feedback.showed_at | nibnut.date("MMM, DD") }} @ {{ edited_feedback.showed_at | nibnut.date("HH:mm") }}</span>
                    </div>
                </h5>
            </template>
            <feedback-rating
                :rating="`${edited_feedback.rating}/5`"
            />
            <div>
                {{ edited_feedback.comments }}
            </div>
        </modal-dialog>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import confirms from "@/nibnut/mixins/Confirms"

import TimelineItem from "./TimelineItem"
import FeedbackRating from "./FeedbackRating"

export default {
    name: "Timeline",
    mixins: [confirms],
    components: {
        TimelineItem,
        FeedbackRating,
        ModalDialog: () => import("@/nibnut/components/ModalDialog/ModalDialog"),
        FeedbackEditorDialog: () => import("@/custom/dialogs/FeedbackEditorDialog")
    },
    methods: {
        filtered_events (filter) {
            const now = this.$dayjs()
            return this.events.filter(event => {
                const event_date = this.$dayjs(event.event_date)
                if(filter < 0) return now.isSameOrAfter(event_date)
                if(filter > 0) return now.isBefore(event_date)
                return true
            })
        },
        edit_feedback (feedback) {
            this.edited_feedback = feedback
            this.editing = true
        },
        delete_event (event, name) {
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete Action"),
                    message: this.translate(
                        "Do you really want to delete the action '{name}' ({date})? There is no undo...",
                        {
                            name,
                            date: event.event_date ? this.nibnut_filter("nibnut.date", [event.event_date]) : this.translate("No date")
                        }
                    ),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete"),
                    event
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirmation_props.event) {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.confirmation_props.event.type,
                        id: this.confirmation_props.event.id
                    }
                ).then(() => {
                    this.done_confirming()
                }).catch(this.receive_error)
            } else this.done_confirming()
        }
    },
    props: {
        events: { // array of listing_actions and listing_showings
            type: Array,
            default () {
                return []
            }
        },
        listing: {
            type: Object,
            required: true
        },
        editable: { // only add realtor actions - showings can never be edited here
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            editing: false,
            edited_feedback: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.timeline {
    $buffer_size: $layout-spacing-lg + $unit-16;
    position: relative;
    padding-left: $buffer_size;

    & > .timeline-item {
        a:not(.hover-disabled):hover {
            text-decoration: none;
        }
        .timeline-icon {
            position: relative;
            top: 1px;

            i.las.la-plus {
                transition: transform .2s ease;

                &.la-rotate-45 {
                    transform: rotate(45deg);
                }
            }
        }
        & > .timeline-right {
            position: absolute;
            left: -$buffer_size;
            top: 0;
            width: $buffer_size;
            text-align: right;
            overflow: hidden;
            white-space: nowrap;
            padding-right: $layout-spacing-lg;

            .text-small {
                font-size: 0.6em;
            }
        }
        & > .timeline-content {
            & > .tile > .tile-content {
                & > h6.tile-title {
                    margin-bottom: 0;
                }
                & > .tile-subtitle {
                    font-size: 0.8em;
                }
            }
        }
        &:last-child::before {
            display: none;
        }
    }
}
</style>
