<template>
    <div
        v-if="(!!listing && (!listing.id || !!listing.quick_statistics.has_feedback_or_showings))"
        class="showings-list-container"
    >
        <tabs
            :tabs="tabs"
            :current-tab-id="current_tab_id"
            @click="current_tab_id = $event"
        >
            <template v-slot:tab="{ tab }">
                <h5>{{ tab.title }}</h5>
            </template>
            <div v-if="is_at_least_realtor" v-show="(current_tab_id === 'feedback')" class="mt-4">
                <feedback-list
                    :listing-id="listing.id"
                    :min-date="feedbackMinDate"
                    @loaded="feedback_counts = $event"
                />
            </div>
            <agenda
                 v-show="(current_tab_id === 'agenda')"
                :listing-id="listing.id"
                @loaded="showings_counts = $event"
            />
        </tabs>
    </div>
</template>

<script>
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import { Tabs } from "@/nibnut/components"
import Agenda from "./Agenda"
import FeedbackList from "./FeedbackList"

export default {
    name: "ShowingsList",
    mixins: [addl_profile_utilities],
    components: {
        Tabs,
        Agenda,
        FeedbackList
    },
    watch: {
        showings_counts: "refresh_totals",
        feedback_counts: "refresh_totals"
    },
    methods: {
        refresh_totals () {
            this.$emit("loaded", { ...this.showings_counts, incomplete_feedback: this.feedback_counts.pending + this.feedback_counts.unpublished })
        }
    },
    computed: {
        tabs () {
            const feedback_counts = []
            if(this.feedback_counts.pending) feedback_counts.push(this.translate("{n} Pending", { n: this.nibnut_filter("nibnut.number", [this.feedback_counts.pending, "0,0"]) }))
            if(this.feedback_counts.unpublished) feedback_counts.push(this.translate("{n} Unpublished", { n: this.nibnut_filter("nibnut.number", [this.feedback_counts.unpublished, "0,0"]) }))
            const feedback_label = feedback_counts.length ? `: ${feedback_counts.join(" | ")}` : ""
            return [
                {
                    id: "agenda",
                    title: this.translate("Showings")
                },
                {
                    id: "feedback",
                    title: this.translate("Feedback") + feedback_label
                }
            ]
        }
    },
    props: {
        listing: {
            type: Object,
            default () {
                return null
            }
        },
        feedbackMinDate: {
            default: null
        }
    },
    data () {
        return {
            current_tab_id: "agenda",
            showings_counts: { showings_today: 0 },
            feedback_counts: { pending: 0, unpublished: 0, published: 0 }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.showings-list-container {
    .tab-container {
        padding: $unit-4;
    }
    .card {
        .card-body.contact-info {
            display: flex;
            flex-wrap: wrap;

            & > div {
                &:first-child {
                    flex: 1 1 auto;
                    padding-right: $unit-1;
                }
                &.contact-buttons {
                    white-space: nowrap;
                }
            }
        }
    }
    a img {
        max-height: 1em;
    }
    .nibnut-data-table {
        td {
            width: 40%;
            height: 0;
            vertical-align: top;
            @media(max-width:$size-xl) {
                width: 33.333%;
            }
            @media(max-width:$size-sm) {
                height: auto;
            }

            &:first-child {
                width: 20%;
                @media(max-width:$size-xl) {
                    width: 33.333%;
                }
            }

            .btn.btn-block {
                text-align: left;
                padding-left: 0;
            }

            .cell-feedback {
                flex-direction: column;
                height: 100%;
                @media(max-width:$size-lg) {
                    height: auto;
                }
            }
        }
        & > .data-table-content {
            padding: 0;

            .image-holder {
                position: relative;
                width: 20%;
                @media(max-width:$size-sm) {
                    width: auto;
                }

                h6 {
                    display: inline-block;
                }
            }
            .tooltip {
                position: relative;
            }
        }
    }
    &.not-loaded {
        height: 0px;
        overflow: hidden;
    }
}
</style>
