<template>
    <div class="input-group url-input">
        <base-dropdown
            :id="`${identifier}-protocol`"
            :name="`${name}_protocol`"
            :value="protocol"
            :size="size"
            :options="protocols"
            :disabled="!editable || disabled"
            :required="true"
            @change="set_protocol"
        />
        <base-input
            ref="field"
            :id="identifier"
            :name="name"
            :value="url"
            :placeholder="placeholder"
            :aria-describedby="!!has_slot('hint')?`${identifier}-description`:false"
            :disabled="!editable || disabled"
            :required="required && touched"
            :size="size"
            :autocomplete="autocomplete"
            autocapitalize="none"
            @focus="focus"
            @touchstart="touchstart"
            @keyup="$emit('keyup', $event)"
            @keydown="keydown"
            @blur="blur"
        />
        <base-link
            v-if="!!value"
            :href="value"
            target="_blank"
            :alt="translate('Open in new window')"
            class="btn"
        >
            <open-icon glyph="external-link-alt" />
        </base-link>
    </div>
</template>

<script>
import debounce from "lodash/debounce"

import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"

import BaseInput from "@/nibnut/components/Inputs/BaseInput"
import BaseDropdown from "@/nibnut/components/Inputs/BaseDropdown"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "UrlInput",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        BaseInput,
        BaseDropdown,
        BaseLink,
        OpenIcon
    },
    watch: {
        editable: "maybe_focus_field"
    },
    methods: {
        focus_field () {
            this.touched = true
            this.$refs.field.focus()
            this.$refs.field.select()
        },
        maybe_focus_field () {
            if(this.editable) setTimeout(this.focus_field, 150)
        },
        focus () {
            this.$emit("focus")
        },
        keydown: debounce(function (event) {
            if(this.alphanumeric && this.iOS) this.touching = false
            this.$emit("keydown")
        }, 300),
        blur (event) {
            if(this.alphaNumeric && this.iOS) this.touching = false
            if(event.target.value !== this.value) {
                const components = this.url_components(this.value)
                const new_components = this.url_components(event.target.value)
                if(new_components.protocol) components.protocol = new_components.protocol
                components.url = new_components.url
                this.emit_url(components)
            }
        },
        set_protocol (event) {
            const components = this.url_components(this.value)
            components.protocol = event.target.value
            this.emit_url(components)
        },
        url_components (url) {
            const components = { protocol: "https", url: "" }
            if(url) {
                const matches = url.match(/^(?:(?:(.+?):)?\/\/)?(.+)$/i)
                if(matches) {
                    if(matches[1] && matches[1].match(/^https?$/i)) components.protocol = matches[1]
                    components.url = matches[2]
                }
            }
            return components
        },
        emit_url (components) {
            const url = components.url ? `${components.protocol}://${components.url}` : null
            if(url !== this.value) this.$emit("input", url, this.name)
        }
    },
    computed: {
        protocols () {
            return [
                { id: "http", name: "http://" },
                { id: "https", name: "https://" }
            ]
        },
        protocol () {
            return this.url_components(this.value).protocol
        },
        url () {
            return this.url_components(this.value).url
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        autocomplete: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        required: {
            type: Boolean,
            required: true
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            touched: false
        }
    }
}
</script>

<style lang="scss">
.input-group.url-input {
    & > .form-select, & > .btn {
        flex: 0 0 auto;
    }
}
</style>
