<template>
    <div class="seller-calendar">
        <events-calendar
            ref="vuecal"
            :events="events"
            :editable-events="{ title: false, drag: false, resize: false, delete: false, create: false }"
            :activeView="activeView"
            :time-cell-height="timeCellHeight"
            @edit="edit"
            @view-changed="view_changed"
        />

        <event-viewer-dialog
            v-if="editing_event_record"
            :show.sync="editing_event_record"
            :record="event_record"
            :feedback="event_record_feedback(event_record)"
        />
    </div>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import is_calendar_data_source from "@/custom/mixins/IsCalendarDataSource"

import EventViewerDialog from "@/custom/dialogs/EventViewerDialog"

export default {
    name: "SellerCalendar",
    mixins: [is_calendar_data_source],
    components: {
        EventViewerDialog
    },
    watch: {
        listingId: "reset"
    },
    methods: {
        pre_load () {
            this.set_state_values({
                filter_on: "listing_id",
                filter: this.listingId
            })
        },
        edit (event, dom_event) {
            if(dom_event.target && !!dom_event.target.closest("a")) return false
            const record = this.entity_record(event._entity, event._id)
            if(!record) return false
            if(event._entity === "listing_action") {
                if(record.description) this.start_editing(event, record)
            }
            const feedback = this.event_record_feedback(record)
            if(!!feedback && !!feedback.comments) this.start_editing(event, record)
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return "seller-calendar"
        },
        can_refresh () {
            return !!this.profile_id && !!this.state.from && !!this.state.to && !!this.listingId && !!this.entity
        },
        fields () {
            return ["fieldset::calendar", "ns::listing;fieldset::calendar", "ns::listing_action;fieldset::calendar", "ns::listing_feedback;fieldset::calendar"]
        },
        actions () {
            const listing_ids = this.rows.map(showing => showing.listing_id)
            return this.entity_records("listing_action").filter(action => {
                return listing_ids.indexOf(action.listing_id) >= 0
            })
        },
        events () {
            const events = this.showing_events

            this.actions.forEach(action => {
                events.push({
                    start: action.executed_on,
                    end: action.executed_on,
                    allDay: true,
                    title: action.name,
                    class: "realtor-action",
                    _entity: "listing_action",
                    _id: action.id
                })
            })
            return events
        }
    },
    props: {
        listingId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            relation_ids: ["listing", "listing_feedback", "listing_action"]
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.seller-calendar {
    .vuecal__event {
        &.not-confirmed {
            background-color: $brand-color-dark;
            color: $light-color;
        }
        &.confirmed {
            background-color: $primary-color;
            color: $light-color;
        }
        &.realtor-action {
            background-color: $tertiary-color-dark;
            color: $light-color;
        }
    }
}
</style>
