<template>
    <div class="columns stat-counters">
        <div
            v-for="counter in counters"
            :key="counter.id"
            class="column col-3 col-md-4 col-sm-6"
        >
            <div class="card">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-variable">
                            <h6 class="text-gray">{{ counter.title }}</h6>
                            <h3>{{ counter.count | nibnut.number(counter.format || "0,0") }}</h3>
                        </div>
                        <open-icon
                            v-if="!!counter.glyph"
                            :glyph="counter.glyph"
                            class="flex-static text-primary"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "StatCounters",
    components: {
        OpenIcon
    },
    props: {
        counters: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss">
.stat-counters {
    justify-content: center;
    align-items: center;
    h3 {
        margin-bottom: 0;
    }
    i.las {
        font-size: 2.5rem;
        line-height: 3rem;
    }
}
</style>
