<template>
    <div class="showings-calendar">
        <events-calendar
            :events="events"
            :editable-events="{ title: false, drag: true, resize: true, delete: true, create: true }"
            :activeView="activeView"
            :time-cell-height="timeCellHeight"
            @edit="edit"
            @create="create"
            @view-changed="view_changed"
            @cell-click="maybe_create_event"
            @event-change="sync_showing"
            @event-delete="delete_showing"
        />

        <showing-editor-dialog
            v-if="editing_event_record"
            :show.sync="editing_event_record"
            :record="event_record"
            :feedback="event_record_feedback(event_record)"
            @saved="sync_event"
            @create="save_new_showing"
        />
    </div>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import is_calendar_data_source from "@/custom/mixins/IsCalendarDataSource"
import { handles_saving } from "@/nibnut/mixins"

const min_length = 15
const default_length = 30

export default {
    name: "ShowingsCalendar",
    mixins: [handles_saving, is_calendar_data_source],
    methods: {
        sync_showing ({ event }) {
            const showing = this.entity_record("listing_showing", event._id)
            if(showing) {
                this.record_id = showing.id
                this.save_data_for_record_id(this.entity, this.record_id, {
                    starts_at: this.$dayjs(event.start).format("YYYY-MM-DD HH:mm:ss"),
                    ends_at: this.$dayjs(event.end).format("YYYY-MM-DD HH:mm:ss")
                })
            }
        },
        sync_event () {
            if(this.event && this.event_record) {
                this.event.start = this.$dayjs(this.event_record.starts_at).toDate()
                this.event.end = this.$dayjs(this.event_record.ends_at).toDate()
                const listing = this.entity_record("listing", this.event_record.listing_id)
                if(listing) this.event.title = listing.computed_display_title
                this.$emit("updated", this.event_record)
            }
        },
        save_new_showing () {
            if(this.event_record && !this.event_record.id) {
                this.save_data_for_record_id(
                    this.entity,
                    0,
                    {
                        ...this.event_record,
                        fields: ["fieldset::calendar", "ns::listing;fieldset::calendar"],
                        relation_ids: ["listing"]
                    }
                ).then(record => {
                    this.delete_fc = null
                    this.editing_event_record = false
                    this.current_records_ids.push(record.id)
                    this.$emit("created", record)
                })
            }
        },
        edit (event, dom_event) {
            if(dom_event.target && !!dom_event.target.closest("a")) return false
            this.start_editing(event, {
                ...this.entity_record("listing_showing", event._id)
            })
        },
        maybe_create_event (data, vuecal) {
            const date = this.$dayjs(data)
            if(this.date_is_past(date)) return
            const minutes = (Math.round(date.minute() / min_length) * min_length) % 60
            vuecal.createEvent(
                date.minute(minutes).toDate(),
                default_length,
                { _id: 0 }
            )
        },
        create (event, delete_fc) {
            this.delete_fc = delete_fc
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity }
            ).then(record => {
                record.listing_id = 0
                record.starts_at = this.$dayjs(event.start)
                record.ends_at = this.$dayjs(event.end)
                if(record.starts_at.diff(record.ends_at, "minute") < min_length) record.ends_at = record.starts_at.add(default_length, "minute")
                record.starts_at = record.starts_at.format("YYYY-MM-DD HH:mm:ss")
                record.ends_at = record.ends_at.format("YYYY-MM-DD HH:mm:ss")
                this.start_editing(event, record)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        delete_showing (event) {
            const showing = this.entity_record("listing_showing", event._id)
            if(showing) {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: showing.id
                    }
                ).catch(this.receive_error)
            }
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return "showings-calendar"
        },
        can_refresh () {
            return !!this.profile_id && !!this.state.from && !!this.state.to && !!this.entity
        },
        fields () {
            return ["fieldset::calendar", "ns::listing;fieldset::calendar", "ns::listing_feedback;fieldset::calendar"]
        }
    },
    data () {
        return {
            relation_ids: ["listing", "listing_feedback"]
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.showings-calendar {
    .vuecal__event {
        &.not-confirmed {
            background-color: $brand-color-dark;
            color: $light-color;
        }
        &.confirmed {
            background-color: $primary-color;
            color: $light-color;
        }
    }
}
</style>
