<template>
    <span
        v-if="!!content"
        :data-tooltip="content"
        :class="{ [`text-${color}`]: true }"
        class="tooltip app-tooltip"
    >
        <open-icon :glyph="glyph" />
    </span>
</template>

<script>
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "TooltipWidget",
    components: {
        OpenIcon
    },
    props: {
        content: {
            type: String,
            default: null
        },
        glyph: {
            type: String,
            default: "question-circle"
        },
        color: {
            type: String,
            validator: prop => !prop || prop.match(/^(primary|secondary|regular|error|warning|success|gray)$/i),
            default: "gray"
        }
    }
}
</script>

<style lang="scss">
.tooltip.app-tooltip {
    &::after {
        max-width: none;
        width: 320px;
        overflow: visible;
        white-space: normal;
    }
}
</style>
