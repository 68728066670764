<template>
    <section
        id="user-listings"
        class="card"
    >
        <div class="card-header">
            <div class="card-title h5">
                <div class="columns">
                    <div class="column col-9 col-lg-8 col-md-6 col-sm-12">
                        <form-dropdown
                            id="status_id"
                            name="status_id"
                            :value="state.filter"
                            :options="property_status"
                            :required="true"
                            @input="filter_by_status"
                        />
                    </div>
                    <div class="column col-3 col-lg-4 col-md-6 col-sm-12">
                        <list-mode-picker
                            :list-mode="list_mode"
                            size="sm"
                            @input="set_list_mode"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <listings-list
                :list-mode="list_mode"
                :listings="listings"
                :state="state"
                :data-version="data_version"
                :loading="loading"
                :can-add="role_is_at_least_realtor(realtor.role)"
                @sort="sort_by"
                @page="goto_page"
            />
        </div>
    </section>
</template>

<script>
import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"
import { lists_listings } from "@/custom/mixins/ListsListings"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import ListingsList from "@/custom/components/ListingsList"

export default {
    name: "UserListings",
    mixins: [is_remote_data_table_source, addl_misc_utilities, addl_profile_utilities, lists_listings],
    components: {
        FormDropdown,
        ListingsList
    },
    mounted () {
        this.bootstrap()
    },
    watch: {
        profile_id: "bootstrap",
        "user.id": "refresh",
        "user.listing_ids": "refresh"
    },
    methods: {
        bootstrap () {
            this.load_vocabularies([this.constants("vocabularies", "VOCABULARY_LISTING_STATUS").id]).then(this.refresh)
        },
        pre_load () {
            if(this.realtor.role >= this.constants("roles", "ROLE_REALTOR").id) this.set_state_values({ realtor_id: this.realtor.id, seller_id: 0 })
            else this.set_state_values({ realtor_id: 0, seller_id: this.realtor.id })
            if(this.state.filter === null) {
                const status = this.property_status.find(status => !!status.slug.match(/^active$/))
                if(status) this.set_state_value("filter", status.id)
                if(this.user) this.set_state_value("advanced_query", { ...this.constants("base_listing_query") })
                else this.set_state_value("per_page", 50)
            }
        },
        post_load () {
            this.$emit("loaded", this.rows.length ? { lat: this.rows[0].latitude, lng: this.rows[0].longitude } : undefined)
            if(this.filtering_by_status) {
                setTimeout(() => {
                    this.scroll_to(this.$refs.listings_list)
                }, 1)
            }
            this.filtering_by_status = false
        },
        sort_by (field, sort) {
            this.set_state_values({
                sort_by: field,
                sort_dir: sort
            })
            return this.refresh()
        },
        filter_by_status (status_id) {
            this.filtering_by_status = true
            this.filter("status_id", status_id)
        },
        filtered_listings (rows) {
            let listings = [...rows]
            if(!this.state.archived && !this.filtered_by_selection) listings = listings.filter(record => !record.deleted_at)

            listings = this.sort_rows(listings)
            listings = this.filter_rows(listings)
            if(!this.filtered_by_selection) listings = this.search_rows(listings)

            return this.page_rows(listings)
        }
    },
    computed: {
        can_refresh () {
            return this.profile_id && !!this.property_status.length
        },
        fields () {
            return ["fieldset::list"]
        },
        realtor () {
            return this.user || this.profile
        },
        listings () {
            return this.filtered_listings(this.rows) // .filter(listing => listing.is_available))
        },
        property_status () {
            return this.vocabulary("VOCABULARY_LISTING_STATUS")
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            entity: "listing",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "days_on_market",
                sort_dir: "asc",
                filter_on: "status_id",
                filter: null,
                archived: true,
                search: "",
                total: 0,
                found: 0,
                advanced_query: {},
                seller_id: 0,
                realtor_id: 0
            },
            filtering_by_status: false
        }
    }
}
</script>
