<template>
    <div
        v-if="field === 'computed_display_title'"
        class="card clickable"
        @click.prevent="proxy_click"
    >
        <div class="card-image">
            <div
                class="nibnut-aspect-ratio-box nibnut-ratio-gallery"
            >
                <div>
                    <img
                        v-if="listing.computed_poster_url"
                        :src="listing.computed_poster_url"
                        :alt="listing.computed_display_title"
                        loading="lazy"
                        decoding="async"
                        class="img-responsive"
                    />
                    <open-icon
                        v-else
                        glyph="camera"
                        size="2x"
                    />
                </div>
            </div>
            <div class="card-header">
                <div class="card-title h6">
                    {{ showing.starts_at | nibnut.date("MMM, DD") }} @ {{ showing.starts_at | nibnut.date("HH:mm") }}
                </div>
                <div class="card-subtitle">
                    <router-link
                        :to="{ name: 'listing.edit', params: {id: listing.id } }"
                        v-slot="{ href, navigate }"
                        custom
                    >
                        <base-link
                            ref="link"
                            :href="href"
                            class="text-dark"
                            @click.stop="navigate"
                        >
                            {{ showing.display_title }}
                        </base-link>
                    </router-link>
                </div>
            </div>
            <div class="card-body contact-info text-small text-gray">
                <div>
                    <base-link
                        v-if="email.reply_url"
                        :href="email.reply_url"
                        :title="translate('Reply')"
                        target="_blank"
                    >
                        <span v-if="!!realtor_name.realtor">
                            {{ realtor_name.realtor }}
                        </span>
                        <span v-else-if="showing.from">
                            {{ showing.from }}
                        </span>
                        <span v-else>
                            {{ translate("Unknown Realtor") }}
                        </span>
                    </base-link>
                    <span v-else-if="!!realtor_name.realtor">
                            {{ realtor_name.realtor }}
                    </span>
                    <span v-else-if="showing.from">
                        {{ showing.from }}
                    </span>
                    <span v-else>
                        {{ translate("Unknown Realtor") }}
                    </span>
                </div>
                <div>
                    <mailto-link
                        :value="email.phone || ''"
                        filter="telto"
                        class="mr-2"
                        @click.stop
                    />
                    <base-link
                        v-if="email.reply_url"
                        :href="email.reply_url"
                        :title="translate('Reply')"
                        target="_blank"
                        @click.stop
                    >
                        <open-icon v-if="email.reply_url.match(/^mailto/i)" glyph="envelope" />
                        <img v-else src="@/assets/img/touchbase-logo.png" />
                    </base-link>
                </div>
                <div v-if="!!realtor_name.company">{{ realtor_name.company }}</div>
            </div>
            <div
                class="card-footer"
            >
                <form-toggle-input
                    v-if="!feedback.deleted_at"
                    :id="`${showing.id}-published_at`"
                    name="published_at"
                    type="checkbox"
                    :value="!!feedback.published_at"
                    :editable="true"
                    :required="false"
                    @input="toggle_published_at"
                    @click.native.stop
                >
                    {{ translate("Published") }}
                </form-toggle-input>
                <default-button
                    v-if="!!feedback.id"
                    flavor="link"
                    size="sm"
                    :block="true"
                    @click.stop="toggle_deleted_at"
                >
                    <span v-if="!feedback.deleted_at">{{ translate("Feedback Provided") }}</span>
                    <span v-else>{{ translate("No Feedback Provided") }}</span>
                </default-button>
            </div>
        </div>
    </div>
    <div v-else-if="field === 'received_feedback'" class="cell-feedback d-flex">
        <label class="form-label">{{ translate("Received Feedback") }}</label>
        <div
            v-if="!!feedback && (!!feedback.original_comments || !!feedback.ratings)"
            class="d-flex d-flex-columns"
        >
            <form-textbox
                :id="`${showing.id}-original-comments`"
                name="comments"
                :value="feedback.original_comments || translate('No Comments Provided')"
                size="full"
                :required="false"
                :editable="false"
                :disabled="true"
                @input="save"
                @click.native.stop
            />
            <h6 v-if="feedback.ratings" class="mt-2">{{ translate("Buyer Evaluation") }}</h6>
            <div v-if="feedback.ratings" class="columns text-small">
                <feedback-rating
                    v-for="(rating, label) in feedback.ratings"
                    :key="label"
                    :label="label"
                    :rating="rating"
                    class="column col-6 col-xl-12"
                    @input="save"
                    @click.native.stop
                />
            </div>
        </div>
        <span v-else class="text-gray">{{ translate("No Feedback Received") }}</span>
    </div>
    <div v-else-if="field === 'feedback'" class="cell-feedback d-flex">
        <div class="flex-variable">
            <form-textbox
                :id="`${showing.id}-comments`"
                name="comments"
                :value="feedback.comments"
                size="full"
                :required="false"
                :placeholder="feedback.original_comments"
                @input="save"
                @click.native.stop
            >
                <template v-slot:label>
                    {{ $root.translate("Public Comments") }}
                </template>
            </form-textbox>
        </div>
        <div class="flex-static">
            <form-rating-input
                :id="`${showing.id}-rating`"
                name="rating"
                :value="feedback.rating"
                :required="false"
                :editable="true"
                class="mt-2"
                @input="save"
                @click.native.stop
            >
                <template v-slot:label>
                    {{ $root.translate("Public Rating") }}
                </template>
            </form-rating-input>
        </div>
    </div>
</template>

<script>
import { handles_saving, handles_dates } from "@/nibnut/mixins"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import { FormTextbox, FormToggleInput, BaseLink, MailtoLink, DefaultButton, OpenIcon } from "@/nibnut/components"
import FeedbackRating from "@/custom/components/FeedbackRating"
import FormRatingInput from "@/custom/components/FormRatingInput"

export default {
    name: "ShowingsListFeedbackCell",
    mixins: [addl_profile_utilities, handles_saving, handles_dates],
    components: {
        FormTextbox,
        FormToggleInput,
        BaseLink,
        MailtoLink,
        DefaultButton,
        OpenIcon,
        FeedbackRating,
        FormRatingInput
    },
    methods: {
        save (value, field) {
            const old_values = (!this.feedback || !this.feedback.id) ? null : { ...this.feedback }
            this.feedback[field] = value
            this.save_data_for_record_id(this.entity, this.feedback.id, { ...this.feedback, fields: ["fieldset::feedback"] }).then(feedback => this.changed_status(feedback, old_values))
        },
        changed_status (feedback, old_values = null) {
            this.$emit("changed-status", feedback, old_values)
        },
        toggle_published_at () {
            const old_values = (!this.feedback || !this.feedback.id) ? null : { ...this.feedback }
            if(this.feedback.published_at) {
                this.feedback.published_at = null
                this.feedback.published_by = 0
            } else {
                this.feedback.published_at = this.$dayjs().format("YYYY-MM-DD HH:mm:ss")
                this.feedback.published_by = this.profile.id
            }
            if(this.feedback.id) {
                this.save_data_for_record_id(this.entity, this.feedback.id, {
                    published_at: this.feedback.published_at,
                    published_by: this.feedback.published_by,
                    fields: ["fieldset::feedback"]
                }).then(() => this.changed_status(this.feedback, old_values))
            } else this.changed_status(this.feedback)
        },
        toggle_deleted_at () {
            if(this.feedback.id) {
                if(this.feedback.deleted_at) {
                    this.$store.dispatch(
                        "RECORD_RESTORE",
                        {
                            entity: this.entity,
                            id: this.feedback.id,
                            data: {
                                fields: ["fieldset::feedback"]
                            }
                        }
                    ).catch(this.receive_error)
                } else {
                    this.$store.dispatch(
                        "RECORD_DELETE",
                        {
                            entity: this.entity,
                            id: this.feedback.id,
                            data: {
                                fields: ["fieldset::feedback"]
                            }
                        }
                    ).catch(this.receive_error)
                }
            }
        },
        proxy_click () {
            if(this.$refs.link) this.$refs.link.$el.click()
        }
    },
    computed: {
        is_current () {
            return !!this.showing && this.is_past_date(this.showing.starts_at) && this.is_upcoming_date(this.showing.ends_at)
        },
        is_upcoming () {
            return !!this.showing && this.is_upcoming_date(this.showing.starts_at)
        },
        is_past () {
            return !!this.showing && this.is_past_date(this.showing.ends_at)
        },
        realtor_name () {
            const from = this.email.from
            const matches = from ? from.match(/^(.+?)\s+-\s+?(.+)$/) : null
            return {
                realtor: matches ? matches[1] : from,
                company: matches ? matches[2] : null
            }
        }
    },
    props: {
        field: {
            type: String,
            required: true
        },
        showing: {
            type: Object,
            required: true
        },
        feedback: {
            type: Object,
            required: true
        },
        listing: {
            type: Object,
            required: true
        },
        email: {
            type: Object,
            default () {
                return null
            }
        }
    },
    data () {
        return {
            entity: "listing_feedback"
        }
    }
}
</script>
