<template>
    <div class="timeline-item">
        <div
            :class="{ 'text-strikethrough': displayed_event && displayed_event.cancelled, 'text-gray': displayed_event && displayed_event.cancelled }"
            class="timeline-right"
        >
            <div v-if="displayed_event && displayed_event.id">
                <span v-if="date_is_this_year(displayed_event.event_date)">{{ displayed_event.event_date | nibnut.date("DD MMM") }}</span>
                <div v-else>
                    {{ displayed_event.event_date | nibnut.date("DD MMM") }}
                    <div class="text-small">
                        {{ displayed_event.event_date | nibnut.date("YYYY") }}
                    </div>
                </div>
            </div>
        </div>
        <div class="timeline-left">
            <base-link
                v-if="editable && (!displayed_event || !displayed_event.id)"
                href="#"
                class="timeline-icon icon-lg"
                @click.prevent.stop="add_event"
            >
                <open-icon glyph="plus" :class="{ 'la-rotate-45': editing }" />
            </base-link>
            <span
                v-else-if="displayed_event && displayed_event.cancelled"
                :class="{ [`bg-${event_color}`]: true }"
                class="timeline-icon icon-lg"
                style="opacity: 0.7;"
            >
                <open-icon
                    v-if="!!event_icon"
                    :glyph="event_icon"
                />
            </span>
            <base-link
                v-else-if="((event_status === PAST_W_FEEDBACK) || editable) && displayed_event && (displayed_event.type === 'listing_showing')"
                href="#"
                :class="{ [`bg-${event_color}`]: true }"
                class="timeline-icon icon-lg"
                @click.prevent.stop="$emit('edit-feedback', displayed_event.feedback)"
            >
                <open-icon
                    v-if="!!event_icon"
                    :glyph="event_icon"
                />
            </base-link>
            <span
                v-else-if="displayed_event && displayed_event.id"
                :class="{ [`bg-${event_color}`]: true }"
                class="timeline-icon icon-lg"
            >
                <open-icon
                    v-if="!!event_icon"
                    :glyph="event_icon"
                />
            </span>
        </div>
        <div
            :class="{ 'text-strikethrough': displayed_event && displayed_event.cancelled, 'text-gray': displayed_event && displayed_event.cancelled }"
            class="timeline-content"
        >
            <div v-if="editing || (is_editable && displayed_event && !!displayed_event.id)" class="timeline-editor">
                <div class="columns">
                    <div class="column">
                        <form-dropdown
                            id="action_id"
                            name="action_id"
                            :value="displayed_event.action_id"
                            :options="actions"
                            :required="true"
                            :ad-hoc="true"
                            @input="save"
                            @create="add_and_save_action($event, 'action_id')"
                        />
                    </div>
                    <div class="column">
                        <form-date-input
                            id="event_date"
                            name="executed_on"
                            v-model="displayed_event.event_date"
                            position="over"
                            :max="today"
                            :required="true"
                            @input="save_date"
                        />
                    </div>
                </div>
                <form-textbox
                    id="description"
                    name="description"
                    v-model="displayed_event.description"
                    size="sm"
                    :required="false"
                    :placeholder="translate('Description (optional)')"
                    class="my-2"
                    @input="save"
                />
                <div v-if="!displayed_event.id" class="columns">
                    <div class="column">
                        <default-button
                            color="error"
                            :block="true"
                            size="sm"
                            @click.prevent="stop_add_event"
                        >
                            <open-icon glyph="times" />
                        </default-button>
                    </div>
                    <div class="column">
                        <default-button
                            color="success"
                            :block="true"
                            size="sm"
                            @click.prevent="create_event"
                        >
                            <open-icon glyph="check" />
                        </default-button>
                    </div>
                </div>
                <default-button
                    v-else
                    flavor="link"
                    color="error"
                    :block="true"
                    size="sm"
                    @click.prevent="$emit('delete', event, event_name)"
                >
                    <open-icon glyph="trash" /> {{ translate("Delete") }}
                </default-button>
            </div>
            <div v-else-if="displayed_event" class="tile">
                <div class="tile-content">
                    <h6 class="tile-title">
                        {{ event_name }}
                    </h6>
                    <p v-if="!!displayed_event.description" class="tile-subtitle">
                        {{ displayed_event.description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import handles_saving from "@/nibnut/mixins/HandlesSaving"
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"

import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import FormDateInput from "@/nibnut/components/Inputs/FormDateInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"

const UNKNOWN = 0
const FUTURE = 1
const IN_PROGRESS = 2
const PAST_WO_FEEDBACK = 3
const PAST_W_FEEDBACK = 4

export default {
    name: "TimelineItem",
    mixins: [handles_saving, addl_misc_utilities],
    components: {
        FormTextbox,
        FormDropdown,
        FormDateInput,
        DefaultButton,
        BaseLink,
        OpenIcon
    },
    created () {
        this.PAST_W_FEEDBACK = PAST_W_FEEDBACK
    },
    methods: {
        save (value, field) {
            const displayed_event = this.displayed_event
            if(displayed_event) {
                if(displayed_event[field] !== value) displayed_event[field] = value
                if(displayed_event.id) return this.save_field_for_record_id(displayed_event.type, displayed_event.id, displayed_event[field], field)
            }
            return Promise.resolve()
        },
        save_date (date, field, format = "YYYY-MM-DD") {
            const value = this.$dayjs.isDayjs(date) ? date : this.$dayjs(date)
            return this.save(value ? value.format(format) : null, field)
        },
        add_and_save_action (action_name, field) {
            this.$store.dispatch("CREATE_RECORD", {
                entity: "term",
                data: {
                    vocabulary: this.actions[0].vocabulary,
                    name: action_name
                }
            }).then(event => {
                this.save(event.id, field)
            }).catch(this.receive_error)
        },
        add_event () {
            if(this.editing) this.stop_add_event()
            else {
                this.$store.dispatch(
                    "FETCH_RECORD_SHELL",
                    { entity: "listing_action" }
                ).then(shell_record => {
                    this.shell_event = {
                        type: "listing_action",
                        id: 0,
                        action_id: shell_record.action_id,
                        description: shell_record.description,
                        event_date: shell_record.executed_on,
                        event_end_date: null,
                        feedback: null
                    }
                    this.editing = true
                }).catch(error => {
                    this.$error(error.message)
                })
            }
        },
        stop_add_event () {
            this.editing = false
            this.shell_event = null
        },
        create_event () {
            const executed_on = this.$dayjs.isDayjs(this.shell_event.event_date) ? this.shell_event.event_date : this.$dayjs(this.shell_event.event_date)
            return this.save_data_for_record_id(
                "listing_action",
                0,
                {
                    listing_id: this.listingId,
                    action_id: this.shell_event.action_id,
                    description: this.shell_event.description,
                    executed_on: executed_on.format("YYYY-MM-DD HH:mm:ss")
                }
            ).then(() => {
                this.stop_add_event()
            })
        }
    },
    computed: {
        actions () {
            return this.vocabulary("VOCABULARY_LISTING_ACTIONS").filter(term => term.slug !== "listed")
        },
        today () {
            return new Date()
        },
        displayed_event () {
            return this.shell_event || this.event
        },
        event_name () {
            const displayed_event = this.displayed_event
            if(displayed_event.type === "listing_showing") return `Showing @ ${this.nibnut_filter("nibnut.date", [displayed_event.event_date, "HH:mm"])}`
            const action = displayed_event ? this.vocabulary("VOCABULARY_LISTING_ACTIONS").find(action => action.id === displayed_event.action_id) : null
            return action ? action.name : ""
        },
        event_status () {
            const displayed_event = this.displayed_event
            const now = this.$dayjs()
            const start_time = this.$dayjs(displayed_event.event_date)
            const end_time = this.$dayjs(displayed_event.event_end_date)
            if(now.isSameOrAfter(start_time) && now.isSameOrBefore(end_time)) return IN_PROGRESS
            if(now.isAfter(end_time) && (!displayed_event.feedback || displayed_event.feedback.is_pending || displayed_event.feedback.is_unpublished)) return PAST_WO_FEEDBACK
            if(now.isAfter(end_time)) return PAST_W_FEEDBACK
            if(now.isBefore(start_time)) return FUTURE
            return UNKNOWN
        },
        event_color () {
            if(this.displayed_event.type === "listing_showing") {
                const status = this.event_status
                if(status === IN_PROGRESS) return "tertiary" // in progress
                if(status === PAST_WO_FEEDBACK) return "warning"
                if(status === PAST_W_FEEDBACK) return "success"
                if(status === FUTURE) return "primary-light"
            }
            return "gray-dark"
        },
        event_icon () {
            if(this.displayed_event.type === "listing_showing") {
                const status = this.event_status
                if(status === IN_PROGRESS) return "ellipsis-h" // in progress
                if(status === PAST_WO_FEEDBACK) return "comment-slash"
                if(status === PAST_W_FEEDBACK) return "comments"
            }
            return "home"
        },
        is_editable () {
            const displayed_event = this.displayed_event
            const action = displayed_event ? this.vocabulary("VOCABULARY_LISTING_ACTIONS").find(action => action.id === displayed_event.action_id) : null
            return this.editable && !!displayed_event && (displayed_event.type !== "listing_showing") && action && (action.slug !== "listed")
        }
    },
    props: {
        event: {
            type: Object,
            default () {
                return null
            }
        },
        listingId: {
            type: Number,
            default: 0
        },
        editable: { // only add realtor actions - showings can never be edited here
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            shell_event: null,
            editing: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.timeline {
    $buffer_size: $layout-spacing-lg + $unit-16;
    position: relative;
    padding-left: $buffer_size;

    & > .timeline-item {
        a:not(.hover-disabled):hover {
            text-decoration: none;
        }
        .timeline-icon {
            position: relative;
            top: 1px;

            i.las.la-plus {
                transition: transform .2s ease;

                &.la-rotate-45 {
                    transform: rotate(45deg);
                }
            }
        }
        & > .timeline-right {
            position: absolute;
            left: -$buffer_size;
            top: 0;
            width: $buffer_size;
            text-align: right;
            overflow: hidden;
            white-space: nowrap;
            padding-right: $layout-spacing-lg;
        }
        & > .timeline-content {
            & > .tile > .tile-content {
                & > h6.tile-title {
                    margin-bottom: 0;
                }
                & > .tile-subtitle {
                    font-size: 0.8em;
                }
            }
        }
        &:last-child::before {
            display: none;
        }
    }
}
</style>
